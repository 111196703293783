import React, { useState } from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import config from '../config';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const CollapseBar: React.FC = () => {
  const [selectedNav, setSelectedNav] = useState('home');
  return (
    <Navbar.Collapse id="basic-navbar-nav" className="gearlay-bar-tabs text-right">
      <Nav
        className="ml-auto gearlay-collapse-menu"
        activeKey={selectedNav}
        onSelect={(selected) => {
          if (selected) {
            setSelectedNav(selected);
          }
        }}
      >
        <Nav.Link href="https://youtu.be/9uyvYeHrSKk">Demo</Nav.Link>
        <Nav.Link href={config.faqUrl} eventKey="white-paper">
          FAQ
        </Nav.Link>
        <Nav.Link href={config.telegramUrl} eventKey="telegram-group">
          Telegram
        </Nav.Link>
        <Nav.Link href="#team" eventKey="team-message">
          About
        </Nav.Link>
      </Nav>
    </Navbar.Collapse>
  );
};

const NavigationBar: React.FC = () => (
  <Container className="small-padded-container gearlay-bar-container">
    <Navbar bg="transparent" className="gearlay-bar" expand="md">
      <LinkContainer to="/">
        <Navbar.Brand href="replace" className="gearlay-bar-brand">
          <img src="logo.png" className="gearlay-bar-logo" alt="logo" />
        </Navbar.Brand>
      </LinkContainer>
      <Navbar.Toggle aria-controls="basic-navbar-nav" className="gearlay-bar-toggle " />
      <CollapseBar />
    </Navbar>
  </Container>
);

export default NavigationBar;
