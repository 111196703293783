import React, { lazy, Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import Loader from './components/Loader';
import PrivacyPolicy from './pages/Home/PrivacyPolicy';

const HomePage = lazy(() => import('./pages/Home/Home'));

const routes: React.FunctionComponent = () => (
  <BrowserRouter>
    <Suspense fallback={<Loader />}>
      <Switch>
        <Route path="/" exact component={HomePage} />
        <Route path="/privacy-policy" exact component={PrivacyPolicy} />
        <Route path="*">
          <Redirect to="/" />
        </Route>
      </Switch>
    </Suspense>
  </BrowserRouter>
);

export default routes;
