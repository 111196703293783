import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';
import config from '../config';
import { faPaperclip } from '@fortawesome/free-solid-svg-icons';
import { faProductHunt, faTelegram, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const clickableCategories = [
  { text: 'FAQ', url: config.faqUrl, icon: faPaperclip },
  { text: 'Telegram', url: config.telegramUrl, icon: faTelegram },
  { text: 'Twitter', url: config.twitterUrl, icon: faTwitter },
  { text: 'Privacy Policy', url: '/privacy-policy', icon: faProductHunt },
];

const FooterAboutUs: React.FC = () => (
  <Col xs={12} sm={6} md={4} className="about-us-footer">
    <h5 className="about-us-title">Gearlay</h5>
    <p className="about-us-footer-text">
      We're a Canadian startup focused on developing bots and enabling others to do the same easily
      and quickly.
    </p>
    <p className="contact-us">info@gearlay.com</p>
    <p className="copyright">© 2022 Gearlay Inc. All rights reserved.</p>
  </Col>
);

const FooterLinks: React.FC<FooterLinks> = ({ title, clickables }) => (
  <Col xs={12} sm={6} md={4} className="clickable-categories">
    <h5 className="clickable-categories-title">{title}</h5>
    <ul className="clickable-categories-links">
      {clickables.map((clickableItem) => (
        <li key={uuidv4()}>
          <FontAwesomeIcon icon={clickableItem.icon} />
          <a target="_blank" rel="noreferrer" href={clickableItem.url}>
            {clickableItem.text}
          </a>
        </li>
      ))}
    </ul>
  </Col>
);

const Footer: React.FC = () => (
  <footer className="site-footer secondary-background">
    <Container>
      <Row>
        <FooterAboutUs />
        <FooterLinks title="Quick Links" clickables={clickableCategories} />
      </Row>
    </Container>
  </footer>
);

export default Footer;
