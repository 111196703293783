import React, { useEffect } from 'react';
import Aos from 'aos';
import NavigationBar from '../../components/NavigationBar';
import Footer from '../../components/Footer';
import { Container } from 'react-bootstrap';

const PrivacyPolicy: React.FC = () => {
  useEffect(() => {
    Aos.init({
      duration: 2000,
      disable: 'mobile',
      once: true,
    });
  }, []);
  return (
    <div>
      <NavigationBar />
      <Container className="introduction-container">
        Last update: April 24, 2022 <br />
        <h1>Gearlay Privacy Statement</h1>
        Your privacy is important to us. This privacy statement explains the personal data Gearlay
        processes, how Gearlay processes it, and for what purposes. Gearlay offers a wide range of
        products, including server products used to help operate enterprises worldwide, devices you
        use in your home, software that students use at school, and services developers use to
        create and host what's next. References to Gearlay products in this statement include
        Gearlay services, websites, apps, software, servers, and devices. Please read the
        product-specific details in this privacy statement, which provide additional relevant
        information. This statement applies to the interactions Gearlay has with you and the Gearlay
        products listed below, as well as other Gearlay products that display this statement.
        <h2>Personal data we collect</h2>
        Gearlay collects data from you, through our interactions with you and through our products.
        You provide some of this data directly, and we get some of it by collecting data about your
        interactions, use, and experiences with our products. The data we collect depends on the
        context of your interactions with Gearlay and the choices you make, including your privacy
        settings and the products and features you use. We also obtain data about you from third
        parties. If you represent an organization, such as a business or school, that utilizes
        Enterprise and Developer Products from Gearlay , please see the Enterprise and developer
        products section of this privacy statement to learn how we process your data. If you are an
        end user of a Gearlay product or a Gearlay account provided by your organization, please see
        the Products provided by your organization and the Gearlay account sections for more
        information. You have choices when it comes to the technology you use and the data you
        share. When we ask you to provide personal data, you can decline. Many of our products
        require some personal data to provide you with a service. If you choose not to provide data
        required to provide you with a product or feature, you cannot use that product or feature.
        Likewise, where we need to collect personal data by law or to enter into or carry out a
        contract with you, and you do not provide the data, we will not be able to enter into the
        contract; or if this relates to an existing product you're using, we may have to suspend or
        cancel it. We will notify you if this is the case at the time. Where providing the data is
        optional, and you choose not to share personal data, features like personalization that use
        such data will not work for you.
        <h2>How we use personal data</h2>
        Gearlay uses the data we collect to provide you with rich, interactive experiences. In
        particular, we use data to: Provide our products, which includes updating, securing, and
        troubleshooting, as well as providing support. It also includes sharing data, when it is
        required to provide the service or carry out the transactions you request. Improve and
        develop our products. Personalize our products and make recommendations. Advertise and
        market to you, which includes sending promotional communications, targeting advertising, and
        presenting you with relevant offers. We also use the data to operate our business, which
        includes analyzing our performance, meeting our legal obligations, developing our workforce,
        and doing research. In carrying out these purposes, we combine data we collect from
        different contexts (for example, from your use of two Gearlay products) or obtain from third
        parties to give you a more seamless, consistent, and personalized experience, to make
        informed business decisions, and for other legitimate purposes. Our processing of personal
        data for these purposes includes both automated and manual (human) methods of processing.
        Our automated methods often are related to and supported by our manual methods. For example,
        our automated methods include artificial intelligence (AI), which we think of as a set of
        technologies that enable computers to perceive, learn, reason, and assist in decision-making
        to solve problems in ways that are similar to what people do. To build, train, and improve
        the accuracy of our automated methods of processing (including AI), we manually review some
        of the predictions and inferences produced by the automated methods against the underlying
        data from which the predictions and inferences were made. For example, we manually review
        short snippets of voice data that we have taken steps to de-identify to improve our speech
        recognition technologies. This manual review may be conducted by Gearlay employees or
        vendors who are working on Gearlay's behalf.
        <h2>Reasons we share personal data</h2>
        We share your personal data with your consent or to complete any transaction or provide any
        product you have requested or authorized. We also share data with Gearlay -controlled
        affiliates and subsidiaries; with vendors working on our behalf; when required by law or to
        respond to legal process; to protect our customers; to protect lives; to maintain the
        security of our products; and to protect the rights and property of Gearlay and its
        customers.
        <h2>How to access and control your personal data</h2>
        You can also make choices about the collection and use of your data by Gearlay . You can
        control your personal data that Gearlay has obtained, and exercise your data protection
        rights, by contacting Gearlay or using various tools we provide. In some cases, your ability
        to access or control your personal data will be limited, as required or permitted by
        applicable law. How you can access or control your personal data will also depend on which
        products you use. For example, you can: Control the use of your data for interest-based
        advertising from Gearlay by visiting our opt-out page. Choose whether you wish to receive
        promotional emails, SMS messages, telephone calls, and postal mail from Gearlay . Access and
        clear some of your data through the Gearlay privacy dashboard. Not all personal data
        processed by Gearlay can be accessed or controlled via the tools above. If you want to
        access or control personal data processed by Gearlay that is not available via the tools
        above or directly through the Gearlay products you use, you can always contact Gearlay at
        the address in the How to contact us section or by using our web form. We provide aggregate
        metrics about user requests to exercise their data protection rights via the Gearlay Privacy
        Report.
        <h2>Cookies and similar technologies</h2>
        Cookies are small text files placed on your device to store data that can be recalled by a
        web server in the domain that placed the cookie. We use cookies and similar technologies for
        storing and honoring your preferences and settings, enabling you to sign in, providing
        interest-based advertising, combating fraud, analyzing how our products perform, and
        fulfilling other legitimate purposes. Gearlay apps use additional identifiers, such as the
        advertising ID in Windows described in the Advertising ID section of this privacy statement,
        for similar purposes. We also use “web beacons” to help deliver cookies and gather usage and
        performance data. Our websites may include web beacons, cookies, or similar technologies
        from third-party service providers. You have a variety of tools to control the data
        collected by cookies, web beacons, and similar technologies. For example, you can use
        controls in your internet browser to limit how the websites you visit are able to use
        cookies and to withdraw your consent by clearing or blocking cookies.
        <h2>Products provided by your organization—notice to end users</h2>
        If you use a Gearlay product with an account provided by an organization you are affiliated
        with, such as your work or school account, that organization can: Control and administer
        your Gearlay product and product account, including controlling privacy-related settings of
        the product or product account. Access and process your data, including the interaction
        data, diagnostic data, and the contents of your communications and files associated with
        your Gearlay product and product accounts. If you lose access to your work or school account
        (in event of change of employment, for example), you may lose access to products and the
        content associated with those products, including those you acquired on your own behalf, if
        you used your work or school account to sign in to such products. Many Gearlay products are
        intended for use by organizations, such as schools and businesses. Please see the Enterprise
        and developer products section of this privacy statement. If your organization provides you
        with access to Gearlay products, your use of the Gearlay products is subject to your
        organization's policies, if any. You should direct your privacy inquiries, including any
        requests to exercise your data protection rights, to your organization's administrator. When
        you use social features in Gearlay products, other users in your network may see some of
        your activity. To learn more about the social features and other functionality, please
        review documentation or help content specific to the Gearlay product. Gearlay is not
        responsible for the privacy or security practices of our customers, which may differ from
        those set forth in this privacy statement. When you use a Gearlay product provided by your
        organization, Gearlay's processing of your personal data in connection with that product is
        governed by a contract between Gearlay and your organization. Gearlay processes your
        personal data to provide the product to your organization and you, and in some cases for
        Gearlay's business operations related to providing the product as described in the
        Enterprise and developer products section. As mentioned above, if you have questions about
        Gearlay's processing of your personal data in connection with providing products to your
        organization, please contact your organization. If you have questions about Gearlay's
        business operations in connection with providing products to your organization as provided
        in the Product Terms, please contact Gearlay as described in the How to contact us section.
        For more information on our business operations, please see the Enterprise and developer
        products section. For Gearlay products provided by your K-12 school, including Gearlay 365
        Education, Gearlay will: not collect or use student personal data beyond that needed for
        authorized educational or school purposes; not sell or rent student personal data; not use
        or share student personal data for advertising or similar commercial purposes, such as
        behavioral targeting of advertisements to students; not build a personal profile of a
        student, other than for supporting authorized educational or school purposes or as
        authorized by the parent, guardian, or student of appropriate age; and require that our
        vendors with whom student personal data is shared to deliver the educational service, if
        any, are obligated to implement these same commitments for student personal data.
        <h2>Gearlay account</h2>
        With a Gearlay account, you can sign in to Gearlay products, as well as those of select
        Gearlay partners. Personal data associated with your Gearlay account includes credentials,
        name and contact data, payment data, device and usage data, your contacts, information about
        your activities, and your interests and favorites. Signing in to your Gearlay account
        enables personalization and consistent experiences across products and devices, permits you
        to use cloud data storage, allows you to make payments using payment instruments stored in
        your Gearlay account, and enables other features. There are three types of Gearlay account:
        When you create your own Gearlay account tied to your personal email address, we refer to
        that account as a personal Gearlay account. When you or your organization (such as an
        employer or your school) create your Gearlay account tied to your email address provided by
        that organization, we refer to that account as a work or school account. When you or your
        service provider (such as a cable or internet service provider) create your Gearlay account
        tied to your email address with your service provider's domain, we refer to that account as
        a third-party account. If you sign into a service offered by a third party with your Gearlay
        account, you will share with that third party the account data required by that service.
        <br />
        <br />
        If you have questions about this policy, you can contact us as at info@gearlay.com.
      </Container>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
