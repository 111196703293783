// IMPORTANT NOTE: env variables must start with `REACT_APP_`
const config = {
  telegramUrl: process.env.REACT_APP_TELEGRAM_URL || 'https://t.me/gearlay',
  telegramBotUrl: process.env.REACT_APP_TELEGRAM_BOT_URL || 'https://t.me/MaestroWalletBot',
  telegramWhaleBotUrl:
    process.env.REACT_APP_TELEGRAM_WHALE_BOT_URL || 'https://t.me/MaestroWhaleBot',
  telegramBuyBotUrl: process.env.REACT_APP_TELEGRAM_BUY_BOT_URL || 'https://t.me/MaestroBuyBot',
  twitterUrl: process.env.REACT_APP_TWITTER_URL || 'https://twitter.com/gearlay',
  email: process.env.REACT_APP_EMAIL || 'addo@gearlay.com',
  faqUrl: process.env.REACT_APP_FAQ_URL || '/docs/faq.pdf',
  founderIntroVideoUrl:
    process.env.REACT_APP_FOUNDER_INTRO_URL || 'https://www.youtube.com/embed/9FFVMvY2a1A',
  productDemoVideoUrl:
    process.env.REACT_APP_PRODUCT_DEMO_URL || 'https://www.youtube.com/embed/9uyvYeHrSKk',
  founderIntroVideoTitle: process.env.REACT_APP_FOUNDER_VIDEO_TITLE || "Founder's Intro",
  productDemoTitle: process.env.REACT_APP_PRODUCT_DEMO_TITLE || 'Product Demo',
  googleAnalyticsId: process.env.REACT_APP_GOOGLE_ANALYTICS || `UA-209686373-1`,
  mailChimpU: process.env.REACT_APP_MAILCHIMP_U || 'f7c8e3400276b9ef724478669',
  mailChimpId: process.env.REACT_APP_MAILCHIMP_ID || 'b605429994',
};

export default config;
