import React from 'react';
import { Container } from 'react-bootstrap';

const Loader: React.FC = () => (
  <Container className="gearlay-loader">
    <div className="spinner">
      <span className="spinner-inner-1"></span>
      <span className="spinner-inner-2"></span>
      <span className="spinner-inner-3"></span>
    </div>
    <div className="gearlay-spinner-title">Loading...</div>
  </Container>
);

export default Loader;
