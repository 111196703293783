export const getWindowSize = (): WindowSize => ({
  width: window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth,
  height: window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight,
});

const monthNames = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sept',
  'Oct',
  'Nov',
  'Dec',
];

const padWithZeroIfNecessary = (val: number) => `0${val}`.slice(-2);

const prettifyDate = (date: Date) =>
  `${monthNames[date.getMonth()]} ${padWithZeroIfNecessary(date.getDate())}, ${date.getFullYear()}`;

const prettifyTime = (date: Date) =>
  `${padWithZeroIfNecessary(date.getHours())}:${padWithZeroIfNecessary(
    date.getMinutes(),
  )}:${padWithZeroIfNecessary(date.getSeconds())}`;

export const currentTime = (date?: Date): CurrentTimeType => {
  const ct = date ? new Date(date) : new Date();
  return {
    dateTime: ct,
    formattedTime: `${prettifyDate(ct)} ${prettifyTime(ct)}`,
  };
};

export const numberWithCommas = (number: number | string) => {
  if (number > 1) {
    return number.toLocaleString();
  } else {
    return number;
  }
};
