import { createStore } from 'redux';
import rootReducer from '../reducers';
import { windowResize } from '../actions/core.actions';
import { getWindowSize } from '../utils';

const store = createStore(rootReducer);

let debounceTimer: NodeJS.Timeout;
window.addEventListener('resize', () => {
  if (debounceTimer) {
    clearTimeout(debounceTimer);
  }
  debounceTimer = setTimeout(() => {
    const { width, height } = getWindowSize();
    store.dispatch(windowResize(width, height));
  }, 100);
});

export default store;
