import React from 'react';
import { Provider } from 'react-redux';
import ReactGA from 'react-ga';

import config from './config';
import Routes from './Routes';
import store from './store';

ReactGA.initialize(config.googleAnalyticsId);
ReactGA.pageview(window.location.pathname + window.location.search);

const App: React.FC = () => (
  <Provider store={store}>
    <Routes />
  </Provider>
);

export default App;
